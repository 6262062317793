import {Pipe, PipeTransform} from '@angular/core';
import {map, Observable, of} from "rxjs";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {EmployeeService} from "../../core/service/employee.service";
import {PathConstant} from "../../core/constant/path.constant";

@Pipe({
  name: 'userImage'
})
export class UserImagePipe implements PipeTransform {
  constructor(
    private employeeService: EmployeeService,
    private sanitizer: DomSanitizer
  ) {
  }

  transform(employee: any): Observable<SafeUrl> {
    if (employee.profilePicture == PathConstant.DEFAULT_USER_IMAGE) {
      employee.profilePicturePath = PathConstant.DEFAULT_USER_IMAGE;
      return of(employee.profilePicture);
    } else if (employee.profilePicture == PathConstant.DEFAULT_ADMIN_IMAGE) {
      employee.profilePicturePath = PathConstant.DEFAULT_ADMIN_IMAGE;
      return of(employee.profilePicture);
    }

    if (employee.id){
      return this.employeeService.loadProfilePictureById(employee.id).pipe(
        map((data: Blob) => {

          const blob = new Blob([data], {type: 'image/jpeg'});
          const imageUrl = URL.createObjectURL(blob);
          return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
        })
      );
    }else {
      return of(employee.profilePicture);
    }
  }
}
