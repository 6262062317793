export class TimeOffRequestModel {
  requestId?: number;
  employeeId: number;
  timeOffCategory: string;
  compensatory: boolean;
  startDateTime: Date | null;
  endDateTime: Date | null;
  reason?: string;
  requestStatus?: string;
  isAllDay: boolean

  clear() {
    this.requestId = -1;
    this.timeOffCategory = '';
    this.compensatory = false;
    this.startDateTime = null;
    this.endDateTime = null;
    this.reason = '';
    this.requestStatus = '';
    this.isAllDay = false;
  }
}
