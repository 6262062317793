export class SearchDataModel {
  fieldName: string;
  value: string;
  employeeIds: number[];
  status: boolean | null

  constructor(fieldName: string, value: string, ids: number[], status: boolean | null = null) {
    this.fieldName = fieldName;
    this.value = value;
    this.employeeIds = ids;
    this.status = status
  }
}
