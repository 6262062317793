import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {EmployeeBadgeResponseModel} from "../../../core/model/badge/employee-badge-response.model";
import {AuthService} from "../../../core/service/auth.service";
import {Role} from "../../../core/model/employee/role.enum";
import {EmployeeService} from "../../../core/service/employee.service";
import {BadgeSendDialogComponent} from "../badge-send/popup/badge-send-dialog/badge-send-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class BadgeListComponent implements OnInit, OnDestroy {

  @Input() employeeBadgeList: Array<EmployeeBadgeResponseModel> = [];

  @Input() isReceivedBadge = false;

  currentUser: any;
  isManager: boolean;

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private authService: AuthService,
              private employeeService: EmployeeService,
              private dialog: MatDialog,) {
  }

  ngOnInit(){
    this.currentUser = this.authService.getCurrentUser();
    this.setIsManager()
  }

  sendBadge(employeeBadge: EmployeeBadgeResponseModel) {
    if (this.isAllowToSend(employeeBadge)) {
      this.dialog.open(BadgeSendDialogComponent, {
        width: '700px',
        data: employeeBadge.badge
      })
    }
  }

  isAllowToSend(employeeBadge: EmployeeBadgeResponseModel): boolean {

    if (employeeBadge.badge.badgeRole === "CEO" && Role.ADMIN === this.currentUser.role) {
      return true;
    }

    if (employeeBadge.badge.badgeRole === "LEAD" && (this.isManager || Role.ADMIN === this.currentUser.role)) {
      return true;
    }

    if (employeeBadge.badge.badgeRole === "STANDARD") {
      return true;
    }

    return false
  }

  private setIsManager() {
    this.employeeService.isManager(this.currentUser.id)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(result => {
      this.isManager = result
    })
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
