import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {AuthResponseModel} from "../model/auth/auth-response.model";
import {EmployeeResponseModel} from "../model/employee/employee-response.model";
import {LoginCredentialsModel} from "../model/auth/login-credentials.model";
import {EmployeeVerifyModel} from "../model/auth/employee-verify.model";
import {Role} from "../model/employee/role.enum";
import {EmployeeForgotPasswordResponseModel} from "../model/employee/employee-forgot-password-response.model";
import {EmailRequestModel} from "../model/mail/email-request.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly BASE_PATH = environment.apiURL;
  private readonly VERSION = environment.version;

  constructor(
    private http: HttpClient,
    private route: Router,
  ) {
  }

  auth(credentialsDto: LoginCredentialsModel): Observable<AuthResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}login`;
    return this.http.post<AuthResponseModel>(path, credentialsDto);
  }

  verify(verifyDto: EmployeeVerifyModel): Observable<AuthResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}verify`;
    return this.http.post<AuthResponseModel>(path, verifyDto);
  }

  changePassword(verifyDto: EmployeeVerifyModel): Observable<void> {
    const path = `${this.BASE_PATH}${this.VERSION}change-password`;
    return this.http.post<void>(path, verifyDto);
  }

  sendForgetPasswordVerifyCode(emailRequestModel: EmailRequestModel): Observable<void> {
    const path = `${this.BASE_PATH}${this.VERSION}send-verify-code`;
    return this.http.post<void>(path, emailRequestModel);
  }

  checkVerifyCode(code: any): Observable<boolean> {
    const path = `${this.BASE_PATH}${this.VERSION}check-code`;
    return this.http.post<boolean>(path, code);
  }

  setForgotPasswordToken(emailRequestModel: EmailRequestModel): Observable<EmployeeForgotPasswordResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}set-forgot-password-token`;
    return this.http.post<EmployeeForgotPasswordResponseModel>(path, emailRequestModel);
  }

  isVerifiedByEmail(requestDto: EmailRequestModel): Observable<boolean> {
    let path: string = `${this.BASE_PATH}${this.VERSION}is-verified`;
    return this.http.post<boolean>(path, requestDto);
  }

  saveToken(employee: AuthResponseModel, remember: boolean) {
    const storage = remember ? localStorage : sessionStorage;
    const tokenData = {
      token: employee.token,
      expiry: remember ? new Date().getTime() + 30 * 24 * 60 * 60 * 1000 : null
    };

    storage.setItem('tokenData', JSON.stringify(tokenData));
    storage.setItem('currentUser', JSON.stringify(employee));
  }

  getToken(): string | null {
    const tokenDataJson = sessionStorage.getItem('tokenData') || localStorage.getItem('tokenData')
    if (tokenDataJson) {
      const tokenData = JSON.parse(tokenDataJson);

      if (tokenData.expiry && tokenData.expiry < new Date().getTime()) {
        this.logout();
        return null;
      }
      return tokenData.token;
    }
    return null;
  }

  logout(): void {
    const sessionUser = sessionStorage.getItem('currentUser')
    const localUser = localStorage.getItem('currentUser')

    sessionStorage.clear();
    sessionStorage.setItem('logoutFlag', 'true');

    if (sessionUser === localUser) {
      localStorage.removeItem('tokenData');
      localStorage.removeItem('currentUser');
    }
    this.route.navigate(['login']).catch();
  }

  autoLogin(): boolean {
    const logoutFlag = sessionStorage.getItem('logoutFlag');
    if (logoutFlag) {
      return false;
    }

    const token = this.getToken();
    if (token) {
      const userJson = localStorage.getItem('currentUser');
      if (userJson) {
        const currentUser = JSON.parse(userJson);
        this.setCurrentUser(currentUser);
        return true;
      }
    }
    return false;
  }

  getCurrentUser() {
    const userJson = sessionStorage.getItem('currentUser') || localStorage.getItem('currentUser');
    return userJson ? JSON.parse(userJson) : null;
  }

  isLoggedIn(): boolean {
    return !!(this.getToken());
  }

  updateCurrentUserProfilePicture(pic: string) {
    let currentUser: EmployeeResponseModel = this.getCurrentUser();
    currentUser.profilePicture = pic;
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  setCurrentUser(currentUser: AuthResponseModel): void {
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  getCurrentUserRole(): Role {
    return this.getCurrentUser().role;
  }
}
