import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ChartConfiguration, ChartType} from "chart.js";
import {BaseChartDirective} from "ng2-charts";
import {EmployeeService} from "../../../core/service/employee.service";
import {DateService} from "../../../core/util/date.service";

import {AuthService} from "../../../core/service/auth.service";
import {
  EmployeeDiscordStatisticsResponseModel
} from "../../../core/model/employee/employee-discord-statistics-response.model";
import {
  EmployeeDiscordStatisticsRequestModelModel
} from "../../../core/model/employee/employee-discord-statistics-request.model";
import {EmployeeInfoResponseModel} from "../../../core/model/employee/employee-info-response.model";


@Component({
  selector: 'app-user-discord-statistics-chart',
  templateUrl: './user-discord-statistics-chart.component.html',
  styleUrls: ['./user-discord-statistics-chart.component.css']
})
export class UserDiscordStatisticsChartComponent implements  OnChanges {
  @Input() employee: EmployeeInfoResponseModel ;
  @Input() dateRange: { start: Date; end: Date };
  @ViewChild('chartDiv') div!: ElementRef;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public CHART_HEIGHT: number;
  public CHART_WIDTH: number;
  private onlineData: Array<EmployeeDiscordStatisticsResponseModel>;
  private idleData: Array<EmployeeDiscordStatisticsResponseModel>;
  private date: any[] = [];
  private onlineHours: any[] = [];
  private idleHours: any[] = [];
  private endDate = new Date();
  protected showDiagram: boolean;
  protected isDiscordUsernamePresent: boolean = false;
  protected range: { start: Date; end: Date };

  private readonly DATE_FORMAT = "yyyy-MM-dd";
  private readonly STATISTICS_DATE_FORMAT = "dd/MM";
  private readonly DISCORD_ONLINE_STATISTIC = "ONLINE";
  private readonly DISCORD_IDLE_STATISTIC = "IDLE";

  constructor(private employeeService: EmployeeService,
              private dateUtil: DateService,
              private authService: AuthService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setChartWidthAndHeight()
    this.showDiagram = true;
    if (changes['employee'] && changes['employee'].previousValue?.id !== changes['employee'].currentValue.id) {
      this.fetchAllData()
    }
  }

  onDateRangeSelected(dateRange: { start: Date; end: Date }) {
    this.range = dateRange;
    this.fetchAllData()
  }

  lineChartType: ChartType = 'bar';
  labels: string[] = ['Online', 'Idle'];
  lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: this.onlineHours,
        borderColor: '#39b54a',
        label: this.labels[0],
        backgroundColor: '#39b54a',

        pointBackgroundColor: '#39b54a',
        hidden: false,
      },
      {
        data: this.idleHours,
        borderColor: '#f8a41c',
        label: this.labels[1],
        backgroundColor: '#f8a41c',

        pointBackgroundColor: '#f8a41c',
      },
    ],
    labels: this.date,
  };

  lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.7,
      },
    },
    scales: {
      y: {
        position: 'left',
        max: 24,
        min: 0,
        ticks: {
          stepSize: 4,
        },
      },
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Date',
        },
      }
    },
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            if (typeof value === "number") {
              const hours = Math.floor(value);
              const minutes = Math.round((value - hours) * 60);
              return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
            }
            return ""
          }
        }
      },
      legend: {
        display: false,
      },
    },
  };

  public hideLegend(index: number) {
    this.lineChartData.datasets[index].hidden = !this.lineChartData.datasets[index].hidden;
    this.chart.update();
  }

  private fetchAllData() {
    this.getOnlineHours(() => {
      this.getIdleHours(() => {
        this.chart.update();
      });
    });
  }

  private getOnlineHours(callback: () => void) {
    const startDate = this.calculateStartDate();
    const {startDateFormat, endDateFormat} = this.formatDates(startDate);
    const statisticRequestModel = this.createRequestModel(this.DISCORD_ONLINE_STATISTIC, startDateFormat, endDateFormat);
    this.fetchOnlineStatistics(statisticRequestModel, callback);
  }

  private getIdleHours(callback: () => void) {
    const startDate = this.calculateStartDate();
    const {startDateFormat, endDateFormat} = this.formatDates(startDate);
    const statisticRequestModel = this.createRequestModel(this.DISCORD_IDLE_STATISTIC, startDateFormat, endDateFormat);
    this.fetchOnlineStatistics(statisticRequestModel, callback);
  }

  private calculateStartDate(): Date {
    const startDate = new Date(this.endDate);
    startDate.setDate(this.endDate.getDate() - 30);
    return startDate;
  }

  private formatDates(startDate: Date): { startDateFormat: string; endDateFormat: string } {
    const startDateFormat = this.range ? this.dateUtil.getFormattedDate(this.range.start, this.DATE_FORMAT) : this.dateUtil.getFormattedDate(startDate, this.DATE_FORMAT);
    const endDateFormat = this.range ? this.dateUtil.getFormattedDate(this.range.end, this.DATE_FORMAT) : this.dateUtil.getFormattedDate(this.endDate, this.DATE_FORMAT);
    return {startDateFormat, endDateFormat};
  }

  private createRequestModel(status: string, startDateFormat: string, endDateFormat: string): EmployeeDiscordStatisticsRequestModelModel {
    let profileUserDiscordUsername = this.employee.discordUsername;
    const userId = this.employee.id;
    this.isDiscordUsernamePresent = profileUserDiscordUsername !== null && profileUserDiscordUsername !== "";
    return new EmployeeDiscordStatisticsRequestModelModel(
      userId,
      profileUserDiscordUsername,
      startDateFormat,
      endDateFormat,
      status
    );
  }

  private fetchOnlineStatistics(statisticRequestModel: EmployeeDiscordStatisticsRequestModelModel, callback: () => void) {
    this.employeeService.getOnlineStatistics(statisticRequestModel).subscribe(data => {
      if (data.length === 0) {
        this.showDiagram = false
        callback()
      } else {
        this.showDiagram = true;
        if (statisticRequestModel.status === this.DISCORD_ONLINE_STATISTIC) {
          this.onlineData = data;
          this.processOnlineData();
        } else if (statisticRequestModel.status === this.DISCORD_IDLE_STATISTIC) {
          this.idleData = data;
          this.processIdleData();
        }
        callback();
      }
    });
  }

  private processOnlineData() {
    this.clearData(this.date, this.onlineHours);
    this.onlineData.forEach(item => {
      this.date.push(this.dateUtil.getFormattedDate(item.date, this.STATISTICS_DATE_FORMAT));
      this.onlineHours.push(item.hours);
    });
  }

  private processIdleData() {
    this.clearData(this.idleHours);
    this.processData(this.idleData, this.idleHours);
  }


  private clearData(...arrays: any[][]) {
    arrays.forEach(array => array.length = 0);
  }

  private processData(data: any[], targetArray: number[]) {
    data.forEach(item => {
      targetArray.push(item.hours);
    });
  }

  private setChartWidthAndHeight() {
    const windowWidth = window.innerWidth;
    this.CHART_WIDTH = windowWidth > 1650 ? 1550 : 1360;
    this.CHART_HEIGHT = windowWidth > 1650 ? 500 : 500;
  }

}
