import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpService} from "../util/http.service";
import {Observable} from "rxjs";
import {EmployeePageModel} from "../model/employee/employee-page.model";
import {EmployeeResponseModel} from "../model/employee/employee-response.model";
import {EmployeeRequestModel} from "../model/employee/employee-request.model";
import {SearchDataModel} from "../model/search/search-data.model";
import {PageableModel} from "../model/pagination/pageable.model";
import {EmployeeAnalyticsModel} from "../model/employee/employee-analytics-response.model";
import {HttpParams} from "@angular/common/http";
import {EmployeeDiscordStatisticsResponseModel} from "../model/employee/employee-discord-statistics-response.model";
import {EmployeeDiscordStatisticsRequestModelModel} from "../model/employee/employee-discord-statistics-request.model";
import {EmployeeResponseBankData} from "../model/employee/employee-response-bank-data";
import {EmployeeResponsePageModel} from "../model/employee/employee-response-page.model";
import {EmployeeResponsePersonalInfoData} from "../model/employee/employee-response-personal-info-data";
import {EmployeeLink} from "../model/employee/employee-link.model";
import {EmployeeResponseEmploymentData} from "../model/employee/employee-response-employment-data";
import {EmployeePercentageModel} from "../model/employee/employee-percentage.model";
import {EmployeeStaffPageModel} from "../model/employee/employee-staff-page.model";
import {EmployeeInfoPageModel} from "../model/employee/employee-info-page.model";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private readonly BASE_PATH: string = environment.apiURL;
  private readonly VERSION: string = environment.version;
  private readonly EMPLOYEE_CONTROLLER: string = environment.getAllEmployees;

  constructor(private http: HttpService) {
  }

  getAll(page: number): Observable<EmployeeInfoPageModel> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/verified`;
    let params = new HttpParams()
      .set("page", page)
      .set("size", 15);
    return this.http.get<EmployeeInfoPageModel>(path, params);
  }

  getAllForStaff(page: number): Observable<EmployeeStaffPageModel> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/staff`;
    let params = new HttpParams()
      .set("page", page)
      .set("size", 15);
    return this.http.get<EmployeeStaffPageModel>(path, params);
  }

  getAllForManager(page: number, id: number): Observable<EmployeeInfoPageModel> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/verified/manager`;
    let params = new HttpParams()
      .set("page", page)
      .set("size", 15)
      .set("id", id);
    return this.http.get<EmployeeInfoPageModel>(path, params);
  }

  getById(id: number): Observable<EmployeeResponseModel> {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/${id}`;
    return this.http.get<EmployeeResponseModel>(path);
  }

  getAllByIds(ids: number[]): Observable<Array<EmployeeResponseModel>> {
    ids = ids ? ids : [];
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/ids?ids=${ids}`;
    return this.http.get<Array<EmployeeResponseModel>>(path);
  }

  delete(id: number): Observable<void> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/${id}`
    return this.http.delete<void>(path);
  }

  updateEmployeeStatus(status: boolean, id: number): Observable<void> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/status/${id}/${status}`
    return this.http.post<void>(path, null);
  }

  getEmployeeBankData(id: number): Observable<EmployeeResponseBankData> {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/bank/${id}`;
    return this.http.get<EmployeeResponseBankData>(path);
  }

  getEmployeePersonalData = (id: number): Observable<EmployeeResponsePersonalInfoData> => {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/personal/${id}`;
    return this.http.get<EmployeeResponsePersonalInfoData>(path);
  };

  getEmployeeEmploymentData(id: number): Observable<EmployeeResponseEmploymentData> {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/employment/${id}`;
    return this.http.get<EmployeeResponseEmploymentData>(path);
  }


  search(pageable: PageableModel, searchDataModel: SearchDataModel): Observable<EmployeePageModel> {
    let path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/search?page=${pageable.page}`;
    const size: string = `?size=${pageable.size}`;
    if (pageable.size) {
      path += size;
    }
    return this.http.post<EmployeePageModel>(path, searchDataModel);
  }

  create(body: EmployeeRequestModel) {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}`
    return this.http.post<EmployeeResponseModel>(path, body);
  }

  deleteProfilePictureById(id: number): Observable<void> {
    let form: FormData = new FormData();
    form.set("id", id.toString());
    let path: string = `${this.BASE_PATH}${this.VERSION}files/delete/profile-picture/${id}`
    return this.http.post(path, form);
  }

  uploadProfilePictureById(formData: FormData): Observable<void> {
    let path: string = `${this.BASE_PATH}${this.VERSION}files/upload/profile-picture`;
    return this.http.post<void>(path, formData);
  }

  loadProfilePictureById(id: number): Observable<any> {
    let path: string = `${this.BASE_PATH}${this.VERSION}files/${id}/image`;
    return this.http.getBlob<any>(path);
  }

  editPersonalInfo(editDto: EmployeeResponsePersonalInfoData, id: number): Observable<EmployeeResponsePersonalInfoData> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/personal/${id}`;
    return this.http.patch<EmployeeResponsePersonalInfoData>(path, editDto);
  }

  editBankInfo(editDto: EmployeeResponseBankData, id: number):  Observable<EmployeeResponseBankData> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/bank/${id}`;
    return this.http.patch<EmployeeResponseBankData>(path,editDto);
  }

  editLink(updatedData: EmployeeLink, id: number): Observable<EmployeeLink> {
    const url = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/link/${id}`;
    return this.http.patch<EmployeeLink>(url, updatedData);
  }

  editEmploymentData(editDto: EmployeeResponseEmploymentData, id: number): Observable<EmployeeResponseEmploymentData> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/${id}`;
    return this.http.patch<EmployeeResponseEmploymentData>(path, editDto);
  }

  getComputeEmployeesByDepartments(): Observable<Array<EmployeePercentageModel>> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/compute/by-departments`;
    return this.http.get<Array<EmployeePercentageModel>>(path);
  }

  getAnalytics(): Observable<EmployeeAnalyticsModel> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/analytics`;
    return this.http.get<EmployeeAnalyticsModel>(path);
  }

  isManager(id: number): Observable<boolean> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/manager/${id}`;
    return this.http.get<boolean>(path);
  }

  getOnlineStatistics(statisticRequestModelModel: EmployeeDiscordStatisticsRequestModelModel): Observable<Array<EmployeeDiscordStatisticsResponseModel>> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/discord-statistics`;
    return this.http.post<Array<EmployeeDiscordStatisticsResponseModel>>(path, statisticRequestModelModel);
  }

  checkIfCurrentUserIsEmployeeManager(employeeId: number, managerId: number): Observable<boolean> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/employee-manager`;
    let params = new HttpParams()
      .set("employeeId", employeeId)
      .set("managerId", managerId);
    return this.http.get<boolean>(path, params);
  }

  getTodayBirthdays(pageable: PageableModel, date: string): Observable<EmployeeResponsePageModel> {
    const path = `${this.BASE_PATH}${this.VERSION}${this.EMPLOYEE_CONTROLLER}/birthdays`;
    let params = new HttpParams()
      .set("page", pageable.page)
      .set("size", pageable.size)
      .set("date", date);
    return this.http.get<EmployeeResponsePageModel>(path, params);
  }
}
